import TextFilter from "./TextFilter";
import Typography from "@mui/material/Typography";
import NumberFilter from "./NumberFilter";
import React, {useContext} from "react";
import {Checkbox, Divider, Drawer, Grid, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import SortByDropdown, {SortIssueComparator} from "./SortByDropdown";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortIcon from "@mui/icons-material/Sort";
import {SidebarHiddenContext} from "../pages/Home";

export interface SideBarFilterProps {
    drawerOpen: boolean;
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;

    repoLangOptions: string[];
    selectedRepoLangs: string[];
    setSelectedRepoLangs: React.Dispatch<React.SetStateAction<string[]>>;

    issueLabelOptions: string[];
    selectedIssueLabels: string[];
    setSelectedIssueLabels: React.Dispatch<React.SetStateAction<string[]>>;

    repoOptions: string[];
    selectedRepos: string[];
    setSelectedRepos: React.Dispatch<React.SetStateAction<string[]>>;

    selectedStarRange: number[];
    initialStarRange: number[];
    setSelectedStarRange: React.Dispatch<React.SetStateAction<number[]>>;

    noAssignees: boolean;
    setNoAssignees: React.Dispatch<React.SetStateAction<boolean>>;

    setSortFunction: React.Dispatch<React.SetStateAction<SortIssueComparator | null>>;
}

export interface FilterOptionWithCount {
    option: string;
    count: number;
}

function getFilterOptionCounts(options: string[]) {
    const optionCounts = Object.entries(_.countBy(options));
    return _.sortBy(optionCounts, ([_, count]) => count)
        .reverse()
        .map(([option, count]) => {
            return {
                option: option,
                count: count,
            } satisfies FilterOptionWithCount;
        });
}

export default function SideFilterBar(props: SideBarFilterProps) {
    const sidebarHidden = useContext(SidebarHiddenContext);
    const filters = (
        <div className="flex gap-4 flex-col pb-4">
            <div className="flex gap-4 items-center">
                <FilterListIcon
                    color="primary"
                    sx={{fontSize: "23px"}}
                ></FilterListIcon>
                <Typography variant="h6" className="select-none" sx={{color: "primary.main"}}>
                    Filters
                </Typography>
            </div>
            <TextFilter
                title="Repo Programming Language"
                setOptions={props.selectedRepoLangs}
                options={getFilterOptionCounts(props.repoLangOptions)}
                setter={props.setSelectedRepoLangs}
            ></TextFilter>
            <TextFilter
                title="Issue Label"
                setOptions={props.selectedIssueLabels}
                options={getFilterOptionCounts(props.issueLabelOptions)}
                setter={props.setSelectedIssueLabels}
            ></TextFilter>
            <TextFilter
                title="Repository"
                setOptions={props.selectedRepos}
                options={getFilterOptionCounts(props.repoOptions)}
                setter={props.setSelectedRepos}
            ></TextFilter>

            <div>
                <NumberFilter
                    title="Repo Stars"
                    rangeSetter={props.setSelectedStarRange}
                    range={props.selectedStarRange}
                    initialMax={props.initialStarRange[1]}
                    initialMin={props.initialStarRange[0]}
                ></NumberFilter>

                <div className="flex items-center gap-2">
                    <Typography variant="caption" className="select-none text-nowrap" sx={{color: "primary.main"}}>
                        Only show issues with no assignee
                    </Typography>
                    <Checkbox size="small" checked={props.noAssignees}
                              onChange={() => props.setNoAssignees(!props.noAssignees)}/>
                </div>
            </div>

            <Divider></Divider>

            <div className="flex gap-4 items-center">
                <SortIcon color="primary" sx={{fontSize: "23px"}}></SortIcon>
                <Typography variant="h6" className="select-none" sx={{color: "primary.main"}}>
                    Sort
                </Typography>
            </div>
            <SortByDropdown
                sortFunctionSetter={props.setSortFunction}
            ></SortByDropdown>
        </div>
    );

    if (props.drawerOpen) {
        return (
            <Drawer
                open={true}
                onClose={() => props.setDrawerOpen(false)}
                sx={{
                    "& .MuiDrawer-paper": {
                        width: "85%",
                        padding: "24px",
                    },
                }}
            >
                <div className="flex justify-end pb-4">
                    <IconButton
                        color="primary"
                        onClick={() => props.setDrawerOpen(false)}
                        sx={{
                            padding: 0,
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </div>
                <div className="px-4">{filters}</div>
            </Drawer>
        );
    }

    if (!sidebarHidden) {
        return (
            <Grid item xs={4} className="pr-4">
                <div className="sticky top-8 h-full max-h-[80vh] overflow-y-auto overflow-x-hidden">
                    {filters}
                </div>
            </Grid>
        );
    }

    return <></>;
}
