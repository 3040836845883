import Typography from "@mui/material/Typography";
import {theme} from "../theme";
import {Link} from "@mui/material";
import MenuAppBar from "../appbar/MenuAppBar";
import React from "react";

export default function About() {
    return <>
        <MenuAppBar></MenuAppBar>
        <div className="p-8 flex flex-col gap-6" style={{color: theme.palette.primary.main}}>
            <Typography variant="body1" className="!font-semibold">Good First Issues empowers first-time contributors of open-source
                software</Typography>

            <div className="flex flex-col gap-4">
                <Typography variant="body1">
                    This website is primarily targeted at developers who want to contribute to open source software but
                    do not know where or how to start.
                </Typography>

                <Typography variant="body1">
                    By providing developers with filters, this website empowers them to browse and select issues and
                    repositories based on programming languages that they are comfortable with.
                </Typography>

                <Typography variant="body1">
                    In addition, they can select the type of issues they want to address.
                </Typography>
            </div>

            <Typography variant="body2">
                Favicon made by <Link href="https://www.flaticon.com/authors/freepik"
                                      target="_blank" rel="noreferrer">Freepik</Link> and generated with <Link
                href="https://realfavicongenerator.net/" target="_blank">Real Favicon Generator</Link>
            </Typography>
        </div>
    </>
}