import { Autocomplete, TextField, useTheme } from "@mui/material";
import React, { SyntheticEvent } from "react";
import { FilterOptionWithCount } from "./SideFilterBar";

interface TextFilterProps {
  title: string;
  setOptions?: string[];
  options: FilterOptionWithCount[];
  setter: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function TextFilter(props: TextFilterProps) {
  const onChange = (
    _: SyntheticEvent,
    optionsWithCounts: FilterOptionWithCount[],
  ) => {
    props.setter(
      optionsWithCounts.map((optionWithCount) => optionWithCount.option),
    );
  };

  const theme = useTheme();

  return (
    <div>
      <Autocomplete
        multiple
        size="small"
        value={
          props.setOptions?.map((option) => {
            return {
              option: option,
              count: 0,
            } satisfies FilterOptionWithCount;
          }) ?? []
        }
        options={props.options}
        renderInput={(params) => <TextField {...params} label={props.title} />}
        onChange={onChange}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px",
            color: theme.palette.primary.main,
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
          },
        }}
        getOptionLabel={(optionWithCount) => optionWithCount.option}
        renderOption={(props, optionWithCount) => {
          return (
            <span
              {...props}
              key={optionWithCount.option + optionWithCount.count}
              className="flex flex-col px-3.5 py-2 text-primary hover:bg-primary hover:text-background hover:cursor-pointer"
            >
              {optionWithCount.option} ({optionWithCount.count})
            </span>
          );
        }}
        isOptionEqualToValue={(option, value) => option.option === value.option}
        ChipProps={{
          color: "primary",
          size: "small",
        }}
      />
    </div>
  );
}
