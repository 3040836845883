import { Slider } from "@mui/material";
import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";

interface NumberFilterProps {
  title: string;
  rangeSetter: React.Dispatch<React.SetStateAction<number[]>>;
  range: number[];
  initialMin: number;
  initialMax: number;
}

export default function NumberFilter(props: NumberFilterProps) {
  const handleChange = (_: Event, newValue: number | number[]) => {
    setRange(newValue as number[]);
    props.rangeSetter(newValue as number[]);
  };

  const [range, setRange] = React.useState(() =>
    props.range
  );

  useEffect(() => {
    setRange(props.range);
  }, [props.range]);

  return (
    <div className="flex gap-4 items-center">
      <Typography variant="caption" className="select-none text-nowrap" sx={{ color: "primary.main" }}>
        {props.title}
      </Typography>
      <div className="flex w-full pr-5">
        <Slider
          size="small"
          value={range}
          max={props.initialMax}
          min={props.initialMin}
          onChange={handleChange}
          valueLabelDisplay="auto"
          color="primary"
        />
      </div>
    </div>
  );
}
