import * as React from "react";
import {useContext} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import GitHubIcon from "@mui/icons-material/GitHub";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import {IconButton, Link, Tooltip, useTheme} from "@mui/material";
import {SidebarHiddenContext} from "../pages/Home";
import {SmallScreenUpContext} from "../../App";


interface MenuAppBarProps {
    drawerOpen?: boolean;
    setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MenuAppBar(props: MenuAppBarProps) {
    const theme = useTheme();
    const getButtonColorStyle = {
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
        },
    };
    const sidebarHidden = useContext(SidebarHiddenContext);
    const smallScreenUp = useContext(SmallScreenUpContext);

    return (
        <Box>
            <AppBar elevation={0} position="static" color="primary">
                <Toolbar className="flex justify-between items-center py-2">
                    <div
                        className={
                            (sidebarHidden ? "" : "pl-2") + " flex items-center gap-3"
                        }
                    >
                        {sidebarHidden ? (
                            <IconButton
                                sx={getButtonColorStyle}
                                size={smallScreenUp ? "medium" : "small"}
                                onClick={() => props.setDrawerOpen!(!props.drawerOpen)}
                            >
                                <MenuIcon/>
                            </IconButton>
                        ) : (
                            <></>
                        )}

                        <Link
                            href="/"
                            underline="none"
                            color="secondary.main"
                            variant="h5"
                            className="tracking-wide leading-none"
                        >
                            Good First Issues
                        </Link>
                    </div>

                    <div className="flex items-center gap-2">
                        <Tooltip title="Home">
                            <IconButton sx={getButtonColorStyle} href="/" size={smallScreenUp ? "medium" : "small"}>
                                <HomeIcon/>
                            </IconButton>
                        </Tooltip>

                        {smallScreenUp ?
                            <Tooltip title="About">
                                <IconButton sx={getButtonColorStyle} href="/#/about">
                                    <InfoIcon/>
                                </IconButton>
                            </Tooltip> : <></>
                        }

                        <Tooltip title="Contribute to this repo">
                            <IconButton sx={getButtonColorStyle} size={smallScreenUp ? "medium" : "small"}
                                        onClick={() => window.open("https://github.com/iedr/goodfirstissues", "_blank")}>
                                <GitHubIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
