import {Pagination} from "@mui/material";
import {theme} from "../theme";
import Box from "@mui/material/Box";
import React, {useContext} from "react";
import {SidebarHiddenContext} from "../pages/Home";

export interface DataPaginationProps {
    totalPageNumber: number;
    currentPageNumber: number;
    pageNumberSetter: React.Dispatch<React.SetStateAction<number>>;
}

export default function DataPagination(props: DataPaginationProps) {
    const sidebarHidden = useContext(SidebarHiddenContext);

    return <Box className="flex justify-center pt-8">
        <Pagination
            count={props.totalPageNumber}
            page={props.currentPageNumber}
            boundaryCount={sidebarHidden ? 1 : 2}
            onChange={(_, newPageNumber) => {
                props.pageNumberSetter(newPageNumber);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            }}
            showFirstButton
            showLastButton={!sidebarHidden}
            color="primary"
            siblingCount={sidebarHidden ? 0 : 1}
            size={sidebarHidden ? "small" : "medium"}
            sx={{
                "& .MuiPaginationItem-root": {
                    lineHeight: 0,
                },
                "& button.MuiPaginationItem-root:not(.Mui-selected)": {
                    color: theme.palette.primary.main,
                    "&:hover": {
                        color: theme.palette.primary.contrastText,
                        backgroundColor: theme.palette.primary.light,
                    },
                },
                "& .MuiPaginationItem-ellipsis": {
                    color: theme.palette.primary.main,
                },
            }}
        />
    </Box>
}