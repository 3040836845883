import { Autocomplete, TextField, useTheme } from "@mui/material";
import React, { SyntheticEvent } from "react";
import { IssueObject } from "../types";
import _ from "lodash";

interface SortByDropdownProps {
  sortFunctionSetter: React.Dispatch<
    React.SetStateAction<SortIssueComparator | null>
  >;
}

interface SortOptionFunction {
  sortText: string;
  sortFunction: SortIssueComparator;
}

export type SortIssueComparator = (issues: IssueObject[]) => IssueObject[];

export default function SortByDropdown(props: SortByDropdownProps) {
  const onChange = (
    _: SyntheticEvent<Element, Event>,
    value: SortOptionFunction | null,
  ) => {
    props.sortFunctionSetter(() => value?.sortFunction ?? null);
  };

  const sortOptions: SortOptionFunction[] = [
    {
      sortText: "Repo stars (asc)",
      sortFunction: (issues) =>
        _.sortBy(issues, (i) => i.Issue.issue_repo.repo_stars),
    } satisfies SortOptionFunction,
    {
      sortText: "Repo stars (desc)",
      sortFunction: (issues) =>
        _.sortBy(issues, (i) => i.Issue.issue_repo.repo_stars).reverse(),
    } satisfies SortOptionFunction,
    {
      sortText: "Comment count (asc)",
      sortFunction: (issues) =>
        _.sortBy(issues, (i) => i.Issue.Comments.issue_comment_count),
    } satisfies SortOptionFunction,
    {
      sortText: "Comment count (desc)",
      sortFunction: (issues) =>
        _.sortBy(issues, (i) => i.Issue.Comments.issue_comment_count).reverse(),
    } satisfies SortOptionFunction,
    {
      sortText: "Assignee count (asc)",
      sortFunction: (issues) =>
        _.sortBy(issues, (i) => i.Issue.Assignees.issue_assignees_count),
    } satisfies SortOptionFunction,
    {
      sortText: "Assignee count (desc)",
      sortFunction: (issues) =>
        _.sortBy(
          issues,
          (i) => i.Issue.Assignees.issue_assignees_count,
        ).reverse(),
    } satisfies SortOptionFunction,
    {
      sortText: "Created time (asc)",
      sortFunction: (issues) =>
        _.sortBy(issues, (i) => i.Issue.issue_createdAt),
    } satisfies SortOptionFunction,
    {
      sortText: "Created time (desc)",
      sortFunction: (issues) =>
        _.sortBy(issues, (i) => i.Issue.issue_createdAt).reverse(),
    } satisfies SortOptionFunction,
  ];

  const theme = useTheme();

  return (
    <div>
      <Autocomplete
        size="small"
        options={sortOptions}
        renderInput={(params) => <TextField {...params} label="Sort by" />}
        onChange={onChange}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px",
            color: theme.palette.primary.main,
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
          },
        }}
        getOptionLabel={(sortOptionFunction) => sortOptionFunction.sortText}
        renderOption={(props, sortOptionFunction) => {
          return (
            <span
              {...props}
              key={sortOptionFunction.sortText}
              className="flex flex-col px-3.5 py-2 text-primary hover:bg-primary hover:text-background hover:cursor-pointer"
            >
              {sortOptionFunction.sortText}
            </span>
          );
        }}
        isOptionEqualToValue={(option, value) =>
          option.sortText === value.sortText
        }
        ChipProps={{
          color: "primary",
          size: "small",
        }}
      />
    </div>
  );
}
