import React, {useEffect} from "react";
import {IssueObject} from "../types";
import {SortIssueComparator} from "./SortByDropdown";
import _ from "lodash";
import {NUMBER_OF_ISSUES_PER_PAGE} from "../pages/Home";
import SideFilterBar from "./SideFilterBar";

export interface FilterFunctionsProps {
    initialResults: IssueObject[];
    firstRender: React.MutableRefObject<boolean>;
    setResults: React.Dispatch<React.SetStateAction<IssueObject[]>>;
    setTotalPageNumber: React.Dispatch<React.SetStateAction<number>>;
    drawerOpen: boolean;
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FilterFunctions(props: FilterFunctionsProps) {
    // Filters
    const [selectedRepoLangs, setSelectedRepoLangs] = React.useState([] as string[]);
    const [selectedLabels, setSelectedLabels] = React.useState([] as string[]);
    const [selectedRepos, setSelectedRepos] = React.useState([] as string[]);
    const [initialStarRange, setInitialStarRange] = React.useState(
        [] as number[],
    );
    const [selectedStarRange, setSelectedStarRange] = React.useState([] as number[]);
    const [noAssignees, setNoAssignees] = React.useState(false);

    const [filteredResults, setFilteredResults] = React.useState([] as IssueObject[]);

    // Sort
    const [sortFunction, setSortFunction] =
        React.useState<SortIssueComparator | null>(null);

    // Options for dropdowns
    const repoLangOptions = filteredResults
        .flatMap((result) => result.Issue.issue_repo.repo_langs.Nodes)
        .map((node) => (node?.repo_prog_language ? node.repo_prog_language : ""))
        .filter((lang) => lang.trim().length > 0);

    const issueLabelOptions = filteredResults
        .flatMap((result) => result.Issue.issue_labels.Nodes)
        .map((node) => (node?.label_name ? node.label_name : ""))
        .filter((label) => label.trim().length > 0);

    const repoOptions = filteredResults
        .map((result) => result.Issue.issue_repo.repo_name)
        .filter((repo) => repo.trim().length > 0);

    const getFiltersFromStorage = (data: IssueObject[]) => {
        const storedLanguageFilter = localStorage.getItem("languageFilter");
        if (storedLanguageFilter && JSON.parse(storedLanguageFilter).length > 0) {
            setSelectedRepoLangs(JSON.parse(storedLanguageFilter!));
        } else {
            setSelectedRepoLangs([]);
        }

        const storedLabelsFilter = localStorage.getItem("labelsFilter");
        if (storedLabelsFilter && JSON.parse(storedLabelsFilter).length > 0) {
            setSelectedLabels(JSON.parse(storedLabelsFilter!));
        } else {
            setSelectedLabels([]);
        }

        const storedRepoFilter = localStorage.getItem("repositoryFilter");
        if (storedRepoFilter && JSON.parse(storedRepoFilter).length > 0) {
            setSelectedRepos(JSON.parse(storedRepoFilter!));
        } else {
            setSelectedRepos([]);
        }

        const storedStarRange = localStorage.getItem("starRange");
        const issueStars = data.map((issue) => issue.Issue.issue_repo.repo_stars);

        setInitialStarRange([_.min(issueStars) ?? 0, _.max(issueStars) ?? 0]);
        if (storedStarRange && JSON.parse(storedStarRange).length > 0) {
            setSelectedStarRange(JSON.parse(storedStarRange!));
        } else {
            setSelectedStarRange([_.min(issueStars) ?? 0, _.max(issueStars) ?? 0]);
        }

        const storedNoAssignees = localStorage.getItem("noAssignees");
        if (storedNoAssignees && JSON.parse(storedNoAssignees)) {
            setNoAssignees(true);
        }
    };

    useEffect(() => {
        getFiltersFromStorage(props.initialResults);
    }, [props.initialResults]);

    useEffect(() => {
        if (!props.firstRender.current) {
            localStorage.setItem("languageFilter", JSON.stringify(selectedRepoLangs));
            localStorage.setItem("labelsFilter", JSON.stringify(selectedLabels));
            localStorage.setItem("repositoryFilter", JSON.stringify(selectedRepos));
            localStorage.setItem("starRange", JSON.stringify(selectedStarRange));
            localStorage.setItem("noAssignees", JSON.stringify(noAssignees));
        }

        let filteredData = props.initialResults
            .filter((issueObject) =>
                selectedRepoLangs.length > 0
                    ? _.intersection(
                    issueObject.Issue.issue_repo.repo_langs.Nodes?.map(
                        (node) => node.repo_prog_language,
                    ) ?? [],
                    selectedRepoLangs,
                ).length > 0
                    : true,
            )
            .filter((issueObject) =>
                selectedLabels.length > 0
                    ? _.intersection(
                    issueObject.Issue.issue_labels.Nodes?.map(
                        (node) => node.label_name,
                    ) ?? [],
                    selectedLabels,
                ).length > 0
                    : true,
            )
            .filter((issueObject) =>
                selectedRepos.length > 0
                    ? selectedRepos.includes(issueObject.Issue.issue_repo.repo_name)
                    : true,
            )
            .filter((issueObject) =>
                selectedStarRange.length > 0
                    ? issueObject.Issue.issue_repo.repo_stars >= selectedStarRange[0] &&
                    issueObject.Issue.issue_repo.repo_stars <= selectedStarRange[1]
                    : true,
            )
            .filter((issueObject) =>
                noAssignees
                    ? issueObject.Issue.Assignees.issue_assignees_count === 0
                    : true
            );

        if (sortFunction != null) {
            filteredData = sortFunction(filteredData);
        }

        props.setResults(filteredData);
        setFilteredResults(filteredData);

        props.setTotalPageNumber(
            Math.ceil(filteredData.length / NUMBER_OF_ISSUES_PER_PAGE),
        );
    }, [selectedRepoLangs, selectedLabels, selectedRepos, selectedStarRange, noAssignees, sortFunction]);

    return <SideFilterBar
        drawerOpen={props.drawerOpen}
        setDrawerOpen={props.setDrawerOpen}
        issueLabelOptions={issueLabelOptions}
        repoOptions={repoOptions}
        repoLangOptions={repoLangOptions}
        selectedRepoLangs={selectedRepoLangs}
        setSelectedRepoLangs={setSelectedRepoLangs}
        selectedIssueLabels={selectedLabels}
        setSelectedIssueLabels={setSelectedLabels}
        selectedStarRange={selectedStarRange}
        setSelectedStarRange={setSelectedStarRange}
        setSortFunction={setSortFunction} initialStarRange={initialStarRange} noAssignees={noAssignees}
        setNoAssignees={setNoAssignees} selectedRepos={selectedRepos} setSelectedRepos={setSelectedRepos}></SideFilterBar>
}