import React, {createContext, useEffect, useRef} from "react";
import {Grid, LinearProgress, Snackbar, useMediaQuery} from "@mui/material";
import MenuAppBar from "../appbar/MenuAppBar";
import Issues from "../issues/Issues";
import Box from "@mui/material/Box";
import {IssueObject} from "../types";
import {theme} from "../theme";
import Typography from "@mui/material/Typography";
import DataPagination from "../pagination/DataPagination";
import FilterFunctions from "../filters/FilterFunctions";

export const SidebarHiddenContext = createContext(false);
export const NUMBER_OF_ISSUES_PER_PAGE = 10;

export default function Home() {
    // Sidebar
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    // Pagination
    const [totalPageNumber, setTotalPageNumber] = React.useState(0);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(1);

    // Results
    const [results, setResults] = React.useState([] as IssueObject[]);
    const [initialData, setInitialData] = React.useState([] as IssueObject[]);

    // Progress bar
    const [progress, setProgress] = React.useState(10);

    // Error snackbar
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);

    const firstRender = useRef(true);

    // Fetch data
    useEffect(() => {
        fetch(
            "https://raw.githubusercontent.com/darensin01/goodfirstissues/master/backend/data.json",
            {cache: "default"},
        )
            .then((res) => res.json())
            .then((data: IssueObject[]) => {
                setProgress(50);

                const filteredData = data.filter(
                    (issueObject) => issueObject.Issue.issue_url.trim().length > 0,
                );

                setProgress(60);
                setResults(filteredData);

                setProgress(70);
                setInitialData(filteredData);

                setProgress(100);
                firstRender.current = false;
            })
            .catch((err) => {
                console.log(err.message);
                setErrorSnackbarOpen(true);
            });
    }, []);

    const sidebarHidden = useMediaQuery(theme.breakpoints.down("md"));

    const progressBar = (
        <Box className="p-16 pt-32">
            <LinearProgress color="primary" variant="determinate" value={progress}/>
            <Typography variant="caption" sx={{color: "primary.light"}}>
                {progress}%
            </Typography>
        </Box>
    )

    return (
        <SidebarHiddenContext.Provider value={sidebarHidden}>
            <MenuAppBar
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
            ></MenuAppBar>

            {
                errorSnackbarOpen
                    ? <Snackbar
                        open={errorSnackbarOpen}
                        autoHideDuration={3000}
                        onClose={() => setErrorSnackbarOpen(false)}
                        message="There was an error loading data. Please try again later."
                    />
                    : <></>
            }

            {
                progress !== 100
                    ? progressBar
                    : <Grid
                        container
                        className={sidebarHidden ? "p-4" : "p-8"}
                        sx={{bgcolor: "secondary"}}
                    >

                        <FilterFunctions initialResults={initialData} firstRender={firstRender} setResults={setResults}
                                         setTotalPageNumber={setTotalPageNumber} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}/>

                        <Grid
                            item
                            xs={sidebarHidden ? 12 : 8}
                            className={sidebarHidden ? "" : "pl-4"}
                        >
                            <Issues
                                issues={results.slice(
                                    (currentPageNumber - 1) * NUMBER_OF_ISSUES_PER_PAGE,
                                    currentPageNumber * NUMBER_OF_ISSUES_PER_PAGE,
                                )}
                            ></Issues>

                            <DataPagination totalPageNumber={totalPageNumber} currentPageNumber={currentPageNumber}
                                            pageNumberSetter={setCurrentPageNumber}/>
                        </Grid>
                    </Grid>
            }
        </SidebarHiddenContext.Provider>
    );
}