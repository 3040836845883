import * as React from "react";
import { useContext } from "react";
import {
  Badge,
  Card,
  CardContent,
  Chip,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment";
import StarIcon from "@mui/icons-material/Star";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {IssueObject} from "../types";
import {SidebarHiddenContext} from "../pages/Home";

interface IssueCardProps {
  issue: IssueObject;
}

export default function IssueCard(props: IssueCardProps) {
  const theme = useTheme();
  const sidebarHidden = useContext(SidebarHiddenContext);

  const issue = props.issue.Issue;
  const createdAtDatetime = moment(issue.issue_createdAt);
  const datetimeAgo = createdAtDatetime.fromNow();
  const repoStars = issue.issue_repo.repo_stars;
  const issueAssigneeCount = issue.Assignees.issue_assignees_count;
  const issueCommentCount = issue.Comments.issue_comment_count;

  const chipWithLabel = (label: string) => (
    <Chip
      label={label}
      size="small"
      color="info"
      key={label}
      sx={{
        "& .MuiChip-label": {
          padding: "8px",
          fontSize: "0.8rem",
          lineHeight: 0,
          color: theme.palette.secondary.main,
        },
      }}
    />
  );

  const goToIssue = (url: string) => {
    return window.open(url, "_blank");
  };

  return (
    <Card
      elevation={0}
      className="hover:cursor-pointer !rounded-none"
      sx={{
        "& .MuiCardContent-root": {
          "&:hover": {
            backgroundColor: theme.palette.secondary.light,
            cursor: "default",
          },
        },
      }}
    >
      <CardContent className="!p-4">
        <div className="flex items-center justify-between">
          <div className="flex flex-col text-left gap-0.5">
            <Typography
              variant="h6"
              component="div"
              sx={{ color: "primary.main" }}
            >
              {issue.issue_title}
            </Typography>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{ color: "primary.light" }}
            >
              Repository: {issue.issue_repo.repo_name}
            </Typography>
            <Typography variant="caption" sx={{ color: "info.dark" }}>
              {createdAtDatetime.format("LL")} ({datetimeAgo})
            </Typography>

            <div className="flex pt-6 gap-6">
              <Tooltip title={`Repository stars: ${repoStars}`}>
                <Badge
                  badgeContent={repoStars}
                  color="info"
                  max={999}
                  showZero={true}
                  sx={{ span: { color: "secondary.main" } }}
                >
                  <StarIcon fontSize="small" color="info" />
                </Badge>
              </Tooltip>

              <Tooltip title={`Issue assignees: ${issueAssigneeCount}`}>
                <Badge
                  badgeContent={issueAssigneeCount}
                  color="info"
                  max={999}
                  showZero={true}
                  sx={{ span: { color: "secondary.main" } }}
                >
                  <AssignmentIcon fontSize="small" color="info" />
                </Badge>
              </Tooltip>

              <Tooltip title={`Issue comments: ${issueCommentCount}`}>
                <Badge
                  badgeContent={issueCommentCount}
                  color="info"
                  max={999}
                  showZero={true}
                  sx={{ span: { color: "secondary.main" } }}
                >
                  <ModeCommentIcon fontSize="small" color="info" />
                </Badge>
              </Tooltip>
            </div>

            {!sidebarHidden ? (
              <>
                <div className="flex pt-4 gap-2 flex-wrap select-none">
                  <Typography variant="caption" sx={{ color: "info.main" }}>
                    Labels:
                  </Typography>
                  {issue.issue_labels.Nodes?.map((label) =>
                    chipWithLabel(label.label_name),
                  )}
                </div>

                <div className="flex pt-2 gap-2 flex-wrap select-none">
                  <Typography variant="caption" sx={{ color: "info.main" }}>
                    Repo languages:
                  </Typography>
                  {issue.issue_repo.repo_langs.Nodes?.map((progLang) =>
                    chipWithLabel(progLang.repo_prog_language),
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <Tooltip title="Go to issue" enterDelay={1000}>
            <IconButton
              color="primary"
              onClick={() => goToIssue(issue.issue_url)}
            >
              <OpenInNewIcon></OpenInNewIcon>
            </IconButton>
          </Tooltip>
        </div>
      </CardContent>
    </Card>
  );
}
