import Box from "@mui/material/Box";
import * as React from "react";
import IssueCard from "./IssueCard";
import {Divider} from "@mui/material";
import {IssueObject} from "../types";
import {Adsense} from "@ctrl/react-adsense";

interface IssuesProps {
    issues: IssueObject[];
}

export default function Issues(props: IssuesProps) {
    const divider = <Divider/>;
    const mapToIssueCard = (issue: IssueObject, index: number) => {
        if (index > 0 && index % 4 === 0) {
          return <div key={"ad_" + index} className="flex flex-col gap-2 pt-2">
              <Adsense
                  client="ca-pub-1130124846637992"
                  slot="5362633000"
                  style={{ display: 'block' }}
                  layout="in-article"
                  format="fluid"
              />
              {divider}
          </div>
        } else {
            return <div key={"issue_" + index}>
                <IssueCard issue={issue}></IssueCard>
                {divider}
            </div>
        }
    }

    return (
        <Box>
            {props.issues.map((issue, index) => {
                return mapToIssueCard(issue, index);
            })}
        </Box>
    );
}
