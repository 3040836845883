import {createTheme} from "@mui/material";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});
export const theme = createTheme({
    typography: {
        fontFamily: ["Helvetica", "Arial", "sans-serif"].join(","),
        fontSize: 16,
        h5: {
            fontSize: '1.8rem',
            [breakpoints.down('md')]: {
                fontSize: '1.5rem',
            },
            [breakpoints.down('sm')]: {
                fontSize: '1.0rem',
            },
        }
    },
    palette: {
        primary: {
            main: "#A63A50",
        },
        secondary: {
            main: "#F0E7D8",
        },
        info: {
            main: "#84969A",
        },
        background: {
            default: "#F0E7D8",
            paper: "#F0E7D8",
        },
    },
});