import {Route, Routes} from "react-router-dom";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import React, {createContext} from "react";
import {theme} from "./components/theme";
import {ThemeProvider, useMediaQuery} from "@mui/material";

export const SmallScreenUpContext = createContext(false);

function App() {
    const smallScreenUp = useMediaQuery(theme.breakpoints.up("sm"));

    return <ThemeProvider theme={theme}>
        <SmallScreenUpContext.Provider value={smallScreenUp}>
            <Routes>
                <Route path="/" element={<Home/>}></Route>
                <Route path="/about" element={<About/>}></Route>
            </Routes>
        </SmallScreenUpContext.Provider>
    </ThemeProvider>
}

export default App;
